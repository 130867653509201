exports.components = {
  "component---src-pages-faleconosco-js": () => import("./../../../src/pages/faleconosco.js" /* webpackChunkName: "component---src-pages-faleconosco-js" */),
  "component---src-pages-redirecionamento-simposio-js": () => import("./../../../src/pages/redirecionamento/simposio.js" /* webpackChunkName: "component---src-pages-redirecionamento-simposio-js" */),
  "component---src-pages-registre-js": () => import("./../../../src/pages/registre.js" /* webpackChunkName: "component---src-pages-registre-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-curso-business-js": () => import("./../../../src/templates/cursoBusiness.js" /* webpackChunkName: "component---src-templates-curso-business-js" */),
  "component---src-templates-curso-js": () => import("./../../../src/templates/curso.js" /* webpackChunkName: "component---src-templates-curso-js" */),
  "component---src-templates-cursos-business-js": () => import("./../../../src/templates/cursosBusiness.js" /* webpackChunkName: "component---src-templates-cursos-business-js" */),
  "component---src-templates-cursos-geral-cursosgeral-js": () => import("./../../../src/templates/CursosGeral/cursosgeral.js" /* webpackChunkName: "component---src-templates-cursos-geral-cursosgeral-js" */),
  "component---src-templates-incompany-js": () => import("./../../../src/templates/incompany.js" /* webpackChunkName: "component---src-templates-incompany-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

